let isTablet = false;
document.addEventListener('DOMContentLoaded', function() {
    yall({
        observeChanges: true,
    });
});

$(document).ready(function() {
    isTablet = isOnTablet();

    acceptCookies();
    triggerMenu();
    triggerSearch();
    // initTabs();
    initDisableButtonOnClick();

    // ie11 product images images
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    var productsIE11 = $('.products').length;

    if (isIE11 && productsIE11 >= 1) {
        console.log('IE11 image replacing');
        $('picture').each(function() {
            var newSource = $(this)
                .find('source:last')
                .attr('data-srcset');
            $(this)
                .find('.lazy')
                .attr('src', newSource)
                .removeClass('lazy');
        });
    }
});

$(window).resize(function() {
    isTablet = isOnTablet();
});

function acceptCookies() {
    if ($.cookie('accepted') == 'true') {
        return;
    }

    $('.js-cookie-popup').show();

    $('.js-cookie-popup').cookieBar({
        secure: true,
        closeButton: '.js-accept-cookies-btn',
    });

    $('.js-cookie-popup').on('cookieBar-close', function() {
        $.cookie('accepted', 'true', { path: '/', domain: document.location.hostname });
    });
}

function triggerMenu() {
    let menuTrigger = $('.js-menu-trigger');
    $(menuTrigger).click(function() {
        $(this).toggleClass('active');
        $('.main-menu').toggleClass('active');
    });

    $('.main-menu .has-dropdown').on('click', function(event) {
        if ($('.responsive-checker').is(':visible')) {
            console.log(event.target.nodeName);
            if (event.target.nodeName == 'A') {
                console.log('returning');
                return;
            }
            if ($(this).hasClass('active')) {
                $(this)
                    .find('ul')
                    .slideUp('slow');
                $(this).removeClass('active');
            } else {
                $('.main-menu .has-dropdown').removeClass('active');
                $('.main-menu .has-dropdown ul').slideUp('slow');
                $(this)
                    .find('ul')
                    .slideDown('slow');
                $(this).addClass('active');
            }
        }
    });
}

function triggerSearch() {
    let searchTrigger = $('.js-mobile-search-trigger');
    $(searchTrigger).click(function() {
        $('.js-header-search').toggleClass('active');
    });
}

function initTabs() {
    if (document.querySelector('.js-tabs')) {
        toggleTabs();
    }
}

function isOnTablet() {
    return $('.responsive-checker').is(':visible');
}

function toggleTabs() {
    let tabLinks = $('.js-tab-link');
    let tabPanes = $('.js-tab-content');

    for (let i = 0; i < tabLinks.length; i++) {
        let currentLink = tabLinks[i];

        $(currentLink).on('click', function(e) {
            e.preventDefault();
            let link = $(this)
                .find('a')
                .attr('href');
            let activePane = link;
            // console.log(activePane);

            //tablet + mobile
            if (isTablet) {
                if ($(this).hasClass('open')) {
                    $(this).removeClass('open');
                    tabLinks.forEach(function(link) {
                        $(link).removeClass('visible');
                    });
                } else if ($(this).hasClass('active')) {
                    $(this).addClass('open');
                    for (let i = 0; i < tabLinks.length; i++) {
                        $(tabLinks[i]).addClass('visible');
                    }
                } else {
                    for (let i = 0; i < tabPanes.length; i++) {
                        $(tabPanes[i]).removeClass('active');
                    }
                    for (let i = 0; i < tabLinks.length; i++) {
                        $(tabLinks[i]).removeClass('active visible open');
                    }
                    $(this).addClass('active visible');
                    $(activePane).addClass('active');
                }
                return;
            }

            // desktop
            for (let i = 0; i < tabLinks.length; i++) {
                $(tabLinks[i]).removeClass('active');
            }

            for (let i = 0; i < tabPanes.length; i++) {
                $(tabPanes[i]).removeClass('active');
            }

            $(this).addClass('active');
            $(activePane).addClass('active');
        });
    }
}

function initDisableButtonOnClick() {
    if (document.querySelector('.js-button-click-disable')) {
        disableButtonOnClick();
    }
}

function disableButtonOnClick() {
    let buttons = $('.js-button-click-disable');

    buttons.on('click', function(e) {
        let btn = $(this);
        // disabled button
        btn.prop('disabled', true);
        // get closest form
        let form = btn.closest('form');
        // submit form
        if (form) {
            form.submit();
        }
    });
}
